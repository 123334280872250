/* eslint-disable @typescript-eslint/no-explicit-any */

import { graphql, navigate } from 'gatsby'
import ProductDetails from 'src/components/sections/ProductDetails'
import type { PageProps } from 'gatsby'
import ReviewProvider from 'src/components/product/CustomerReviews/hooks/useReviews'
import { CustomerReviews } from 'src/components/product/CustomerReviews'
import { mark } from 'src/sdk/tests/mark'
import { gql } from '@vtex/graphql-utils'
import type {
  ProductPageQueryQuery,
  ProductPageQueryQueryVariables,
  GetRatingQuery,
  ProductDetailsFragment_CustomDataFragment,
} from '@generated/graphql'
import ShelfLinx from 'src/components/ShelfLinx/ShelfLinx'
import Page404 from 'src/pages/404'
import { discoverSkuBySlug } from 'src/utils/discoverSkuBySlug'
import axios from 'axios'
import {
  ProductProvider,
  ShippingProvider,
} from 'src/components/product-page/context'
import type { Product } from 'src/components/product-page/context/types/product.type'
import type { SellerInfoQuery } from 'src/components/product/BoxSeller/utils/types'
import { MasterDataLogger } from 'src/errors/logger'
import Seo from 'src/components/seo/Seo'

import { channel } from '../../../store.config'

type ServerData = {
  getRating: GetRatingQuery['getRating']
  getManufacturerCode: {
    manufacturerCode: string
  }
  stockKeepingUnit: {
    manufacturerCode: string
    productRefId: string
    refId: string
    reviewData: GetRatingQuery['getRating']
  }
  customData: ProductDetailsFragment_CustomDataFragment
  productNew: Product
  dataSeller: SellerInfoQuery
  productUrlCanonical: string
}

export type Props = PageProps<
  ProductPageQueryQuery,
  ProductPageQueryQueryVariables,
  unknown,
  ServerData
> & { slug: string }

function Page(props: Props) {
  const { serverData } = props

  if (serverData === null || !serverData.productNew) {
    return null
  }

  const productNotFound =
    !serverData ||
    !serverData?.customData ||
    !serverData?.customData?.items?.length

  if (productNotFound) {
    navigate(`/404/?from=${encodeURIComponent(window.location.pathname)}`)

    return <Page404 />
  }

  const {
    productNew,
    customData,
    productUrlCanonical,
    stockKeepingUnit: { reviewData: getRating },
  } = serverData

  const product: any = customData

  const title = product.name ?? ''
  const description = product.description ?? ''

  const canonical = productUrlCanonical

  return (
    <>
      {/* SEO */}
      <Seo
        title={title}
        description={description}
        canonical={`https://www.decathlon.com.br/${canonical}/p`}
        language="pt-br"
        openGraph={{
          type: 'og:product',
          url: canonical,
          title,
          description,
          images: product.items[0].offerImages?.map((img: any) => ({
            url: img.url,
            alt: img.alternateName,
          })),
        }}
      />

      <ProductProvider
        product={productNew}
        rating={getRating}
        productData={props.serverData as any}
        {...props}
      >
        <ShippingProvider>
          <ProductDetails />
        </ShippingProvider>

        <ShelfLinx
          position="middle"
          page="product"
          productId={product.productId}
        />

        <ShelfLinx
          position="bottom"
          page="product"
          productId={product.productId}
        />

        <ReviewProvider>
          <CustomerReviews initialValue={getRating} />
        </ReviewProvider>

        <ShelfLinx
          position="area-bonus"
          page="product"
          productId={product.productId}
        />
      </ProductProvider>
    </>
  )
}

export const querySSG = graphql`
  query ProductPageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
    allCmsHome(
      filter: { sections: { elemMatch: { name: { eq: "Timer de ofertas" } } } }
    ) {
      nodes {
        id
        sections {
          name
          id
          data
        }
      }
    }
  }
`

export const querySSR = gql`
  query ServerProductPageQuery(
    $id: String!
    $channel: String!
    $direction: String!
    $keep_locales_order: Int!
    $locales: String!
    $nb: Int!
    $page: Int!
    $site: Int!
    $skuId: String!
    $sort: String!
  ) {
    customData(skuId: $skuId) {
      ...ProductDetailsFragment_CustomData
      collectionIds
      categories
      categoriesIds
      sport
    }

    stockKeepingUnit(
      direction: $direction
      keep_locales_order: $keep_locales_order
      locales: $locales
      nb: $nb
      page: $page
      site: $site
      skuId: $skuId
      sort: $sort
    ) {
      manufacturerCode
      productRefId
      refId
      reviewData {
        reviews {
          id
          language
          country
          body
          recommended
          count_up_vote
          count_vote
          firstname
          title
          note
          published_at
          country_label
          range_age
          range_used_since
        }
        reviews_per_page
        total_reviews
        reviews_count
        total_ratings_average_note
        notes {
          key
          count
          count_recommended
        }
        current_page_number
        total_page_number
        most_useful_positive {
          id
          language
          country
          body
          recommended
          count_up_vote
          count_vote
          firstname
          title
          note
          published_at
          country_label
          range_age
          range_used_since
        }
        most_useful_negative {
          id
          language
          country
          body
          recommended
          count_up_vote
          count_vote
          firstname
          title
          note
          published_at
          country_label
          range_age
          range_used_since
        }
      }
    }
  }
`

export const getServerData = async ({
  params: { slug },
  ...urlData
}: {
  params: Record<string, string>
  query: Record<string, string>
}) => {
  const CACHE_CONTROL = `max-age=300, s-maxage=7200, stale-while-revalidate`

  try {
    const skuId = await discoverSkuBySlug(slug)

    const { execute } = await import('src/server')

    const { data } = await execute({
      operationName: querySSR,
      variables: {
        id: skuId,
        channel,
        direction: 'desc',
        keep_locales_order: 1,
        locales: 'pt_BR,pt_PT,es_ES,en,fr_FR,it_IT',
        nb: 3,
        page: 1,
        site: 1078,
        skuId,
        sort: 'createdAt',
      },
    })

    const productNotFound =
      !data || !data?.customData || !data?.customData?.items?.length

    if (productNotFound) {
      const params = new URLSearchParams({
        from: encodeURIComponent(`/${slug}/p`),
      })

      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': CACHE_CONTROL,
          location: `/404/?${params.toString()}`,
        },
      }
    }

    const { data: productNew } = await axios.get(
      `https://decathlonstore.myvtex.com/v1/api/decathlon-pdp/product?productId=${data.customData.productId}`
    )

    const queries = Object.entries(urlData.query)

    if (queries.find((item) => item[0] === 'aSku')) {
      const params = new URLSearchParams(queries)

      params.delete('aSku')
      const verifyParams = Array.from(params.values())

      if (verifyParams.length) {
        return {
          status: 301,
          props: null,
          headers: {
            'cache-control': CACHE_CONTROL,
            location: `/${slug}/p?${params.toString()}`,
          },
        }
      }

      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': CACHE_CONTROL,
          location: `/${slug}/p`,
        },
      }
    }

    const { data: productUrlCanonical } = await axios.get(
      `https://decathlonstore.myvtex.com/v1/api/decathlon-pdp/urlProduct?productId=${data.customData.productId}`
    )

    return {
      status: 200,
      props: {
        ...data,
        productNew,
        productUrlCanonical,
      },
      headers: {
        'cache-control': CACHE_CONTROL,
      },
    }
  } catch (err) {
    console.warn('err =>', err)
    const logger = new MasterDataLogger()

    logger.logRequest({
      context: 'getServerData product page',
      slug,
      error: JSON.stringify(err),
    })

    return {
      status: 500,
      props: {},
      headers: {
        'cache-control': 'public, max-age=0, must-revalidate',
      },
    }
  }
}

Page.displayName = 'Page'
export default mark(Page)
