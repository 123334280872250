import { useContext, useCallback, useEffect } from 'react'
import { useSession } from '@faststore/sdk'
import type { CartItem } from 'src/sdk/cart/validate'
import {
  getObjectAddToCartClickEvent,
  handleClickProductEvent,
} from 'src/utils/gtmEvents'
import type { NotificationMessage } from 'src/components/product-page/context/types/index.type'
import {
  useShipping,
  ProductContext,
} from 'src/components/product-page/context'
import { formatCategoriesForEvent } from 'src/utils/formatted'

import { useUI } from '../ui'
import { useCart } from './useCart'

interface ItemProps extends CartItem {
  setNotification?: (message: NotificationMessage | null) => void
  notificationText?: string
  buyDisabled?: boolean
  category: string
  sellerName: string
  productRefId: string
  refId: string
  cep?: string
  cepIsValidForBuy?: boolean
}

export const useBuyButton = (
  item: ItemProps | null,
  clickButtonAddToCart: boolean,
  setClickButtonAddToCart: (value: boolean) => void
) => {
  const { addItem } = useCart()
  const { openMinicart } = useUI()
  const { setModalShippingOpen } = useShipping()
  const {
    currency: { code },
  } = useSession()

  const {
    productData: { customData: product },
  } = useContext(ProductContext)

  const categoryName = formatCategoriesForEvent(product.categories[0])
  const sportData = product.sport

  const addToCart = useCallback(() => {
    if (!item) {
      return false
    }

    if (item.buyDisabled && item.setNotification && item.notificationText) {
      return item.setNotification({
        message: item.notificationText,
        type: 'variations',
      })
    }

    if ((item.setNotification && !item.cep) || !item.cepIsValidForBuy) {
      return setModalShippingOpen(true)
    }

    item.setNotification?.(null)

    delete item.buyDisabled
    delete item.setNotification
    delete item.notificationText

    const obj = getObjectAddToCartClickEvent({
      id: item.itemOffered.isVariantOf.productGroupID,
      currencyCode: code,
      name: item.itemOffered.isVariantOf.name.toLowerCase(),
      brand: item.itemOffered.brand.name,
      category: sportData,
      variant: `${item.itemOffered.sku} | ${item.itemOffered.name}`,
      price: item.price.toFixed(2),
      quantity: item.quantity,
      dimension1: item.productRefId,
      dimension2: item.refId,
      dimension12: item.seller.identifier,
      dimension13: item.sellerName,
      arborescence: categoryName,
    })

    handleClickProductEvent(obj!)

    addItem(item)
    setClickButtonAddToCart(false)

    return openMinicart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addItem, code, item, openMinicart, setModalShippingOpen])

  useEffect(() => {
    if (clickButtonAddToCart && item?.cepIsValidForBuy) {
      addToCart()
    }
  }, [clickButtonAddToCart, item?.cepIsValidForBuy, addToCart])

  return {
    addToCart,
    'data-testid': 'buy-button',
    'data-sku': item?.id,
    'data-seller': item?.seller.identifier,
  }
}
