import { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web'
import type { FormHandles } from '@unform/core'
import type { ProductItem } from 'src/components/product-page/context/types/product.type'

import { AlertIcon } from './AlertIcon'

export interface SellerObj {
  sellerId: string | undefined
  sellerName: string | undefined
  addToCartLink: string | undefined
  sellerDefault: boolean | undefined
}

interface ErrorsProps {
  emailError: boolean | undefined
  nameError: boolean | undefined
}

export function AvailabilityNotify({
  selectedSku,
}: {
  selectedSku: ProductItem
}) {
  const formRef = useRef<FormHandles>(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [register, setRegister] = useState(false)
  const [errors, setErrors] = useState<ErrorsProps>({
    nameError: undefined,
    emailError: undefined,
  })

  const [sendData, setSendData] = useState(false)

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const validateEmail = (emailVar: string) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(emailVar)
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const validateName = (nameVar: string) => {
    const regex = /^[a-z ,.'-]+$/i

    return regex.test(nameVar)
  }

  const testErrors = () => {
    const testError = {
      nameError: validateName(name),
      emailError: validateEmail(email),
    }

    setErrors(testError)
    !!testError && setSendData(true)
  }

  const handleSubmit = () => {
    testErrors()
    submitData()
  }

  const submitData = useCallback(async () => {
    console.info('submitData', errors)
    const hasError = Object.values(errors).some(
      (error) => error === false || error === undefined
    )

    if (hasError) {
      return
    }

    const objectToSend = {
      name,
      email,
      skuId: selectedSku.itemId,
      locale: 'en-US',
      sellerObj: {
        sellerDefault: true,
        sellerId: selectedSku.sellers[0].sellerId,
        sellerName: selectedSku.sellers[0].sellerName,
        addToCartLink: '',
      },
    }

    const response = await fetch('/api/addAvailabilityNotify', {
      method: 'POST',
      body: JSON.stringify(objectToSend),
      headers: {
        Accept: 'application/vnd.vtex.ds.v10+json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })

    const data = response

    if (data.status === 200) {
      setRegister(true)
    }
  }, [email, errors, name, selectedSku.itemId, selectedSku.sellers])

  useEffect(() => {
    if (!sendData) {
      return
    }

    setSendData(false)
    submitData()
  }, [errors, sendData, submitData])

  return (
    <div className="mt-8">
      <h3 className="text-2xl font-bold text-center text-neutral08 flex items-center justify-center gap-x-2">
        <AlertIcon /> Produto indisponível
      </h3>

      <p className="text-xs text-neutral06 text-center my-6">
        Infelizmente este produto está faltando no momento. Em breve estará
        disponível!
      </p>
      {!register ? (
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="flex items-center gap-x-4">
            <div className="w-full relative">
              <input
                name="name"
                placeholder="Digite seu nome"
                className="w-[100%] py-2 px-4 border rounded text-neutral06"
                onChange={handleChangeName}
              />
              {errors.nameError === false && (
                <span className="pl-1 text-xs text-red-600 absolute bottom-[-18px] left-0">
                  Insira seu nome
                </span>
              )}
            </div>
            <div className="w-full relative">
              <input
                name="email"
                placeholder="Digite seu email"
                type="email"
                className="w-[100%] py-2 px-4 border rounded text-neutral06"
                onChange={handleChangeEmail}
              />
              {errors.emailError === false && (
                <span className="pl-1 text-xs text-red-600 absolute bottom-[-18px] left-0">
                  Insira um E-mail Valido
                </span>
              )}
            </div>
          </div>

          <button className="text-blue uppercase px-10 py-2 border border-blue mx-auto mt-6 flex justify-center items-center w-full">
            Avise-me quando chegar
          </button>
        </Form>
      ) : (
        <p className="text-xs text-blue text-center my-6">
          Você foi cadastrado!
        </p>
      )}
    </div>
  )
}
