import { useState } from 'react'
import { Spinner } from 'src/components/common/Spinner'

import { StarsIcon } from './Icons/StarsIcon'
import { onVoteReview } from './hooks/useVoteReview'
import { useReview } from './hooks/useReviews'

type ReviewCardProps = {
  user: {
    name: string
    years: string
    country: string
  }
  description: string
  title?: string
  votes: number
  stars: number
  reviewWin?: boolean
  reviewId: string
  language?: string
  isTranslated: boolean
}

export function ReviewCard({
  description,
  reviewId,
  stars,
  user,
  votes,
  language,
  reviewWin,
  title,
  isTranslated,
}: ReviewCardProps) {
  const { translatedReview, isLoadingTranslated } = useReview()
  const [hasVote, setHasVote] = useState(false)

  async function handleTranslateReview() {
    translatedReview(reviewId, isTranslated)
  }

  async function handleVoteReview(vote: 0 | 1) {
    const { postReviewVote } = await onVoteReview({
      reviewId,
      vote,
    })

    if (postReviewVote.openvoice) {
      setHasVote(true)
    }
  }

  return (
    <div
      className={
        reviewWin
          ? 'bg-neutral02 px-6 py-8 rounded'
          : 'px-6 py-8 border border-mediumGray rounded'
      }
    >
      <div className="flex justify-between">
        <p className="flex items-center">
          <StarsIcon />
          <span className="text-3xl text-primaryDark ml-2 leading-6 font-bold">
            {stars.toFixed(1)}
          </span>
        </p>
        {language !== 'pt' && (
          <button
            onClick={() => handleTranslateReview()}
            className="text-bluePrimary font-bold flex items-center gap-x-2"
          >
            {isLoadingTranslated.reviewId === reviewId &&
              isLoadingTranslated.loading && <Spinner />}
            {isLoadingTranslated.error && (
              <span className="text-danger">Não foi traduzir!</span>
            )}

            {isTranslated ? 'Texto original' : 'Traduzir'}
          </button>
        )}
      </div>
      {reviewWin ? (
        <div className="mt-4">
          <p className="text-secondarydark text-2xl italic font-bold uppercase">
            Avaliação mais útil
          </p>
          <p className="text-lg text-neutral06 font-bold">{votes} votos</p>
        </div>
      ) : (
        <div className="mt-4">
          <p className="text-2xl font-bold italic uppercase">{user.name}</p>
          <p className="text-lg text-neutral06 font-bold">
            {user.years && `${user.years},`} {user.country}
          </p>
        </div>
      )}
      <div className="mt-4 text-lg text-neutral06 max-w-3xl ">
        {title && (
          <>
            {title} <br />{' '}
          </>
        )}
        <div
          className=" text-lg text-neutral06 max-w-3xl"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      {!reviewWin && (
        <div className="border-t border-mediumGray mt-8 pt-12 pb-4 flex items-center">
          {hasVote ? (
            <p className="text-neutral06 font-bold">
              Obrigado por ajudar outros esportistas.
            </p>
          ) : (
            <>
              <p className="text-neutral06">Este comentário foi útil?</p>
              <button
                className="text-bluePrimary font-bold px-4 ml-4"
                onClick={() => handleVoteReview(1)}
              >
                Sim
              </button>
              <button
                className="text-bluePrimary font-bold px-4 ml-4"
                onClick={() => handleVoteReview(1)}
              >
                Não
              </button>
            </>
          )}
        </div>
      )}
    </div>
  )
}
