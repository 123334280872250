import { createContext, useContext, useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import type { GetRatingQuery } from '@generated/graphql'
import { graphql } from 'gatsby'

import type {
  CurrentReview,
  LoadingTranslated,
  Pagination,
  Resumer,
  ReviewData,
  ReviewT,
} from '../Resumer.types'
import { useTranslationReview } from './useTranslationReview'
import { useGetRating } from './useGetRating'

type ReviewContextData = {
  reviews: ReviewData[]
  currentReviews: CurrentReview[]
  setReviewData: (data: ReviewT[], currentPage: number) => void
  translatedReview: (reviewId: string, translated: boolean) => void
  handleGetReviewData: (
    initialData: GetRatingQuery['getRating'],
    productId: string
  ) => void
  fetchingMore: () => void
  resumer: Resumer
  fetchLoading: boolean
  isLoadingTranslated: LoadingTranslated
  hasNextPage: boolean
  isValidating: boolean
}

type ReviewProviderProps = {
  children: ReactNode
}

const ReviewContext = createContext<ReviewContextData>({} as ReviewContextData)

export default function ReviewProvider({ children }: ReviewProviderProps) {
  const [pagination, setPagination] = useState({} as Pagination)
  const [resumer, setResumer] = useState({} as Resumer)
  const [fetchLoading, setFetchLoading] = useState(false)
  const [reviews, setReviews] = useState<ReviewData[]>([])
  const [currentReviews, setCurrentReviews] = useState<CurrentReview[]>([])
  const [isLoadingTranslated, setIsLoadingTranslated] = useState(
    {} as LoadingTranslated
  )

  const [getTranslation] = useTranslationReview()

  const [getRatingData] = useGetRating()

  useEffect(() => {
    const newList = reviews.map((item) => {
      if (item.translatedLang) {
        const currentReview = item.isTranslated
          ? item.translatedLang
          : item.originalLang

        return {
          ...currentReview,
          isTranslated: item.isTranslated,
        }
      }

      return {
        ...item.originalLang,
        isTranslated: false,
      }
    })

    setCurrentReviews(newList)
  }, [reviews])

  function handleGetReviewData(
    initialData: GetRatingQuery['getRating'],
    productId: string
  ) {
    setPagination({
      currentPage: initialData?.current_page_number ?? 0,
      totalPage: initialData?.total_page_number ?? 0,
      totalReviews: initialData?.total_reviews ?? 0,
      variables: {
        direction: 'desc',
        keep_locales_order: 1,
        locales: 'pt_BR,pt_PT,es_ES,en,fr_FR,it_IT',
        nb: 3,
        page: 1,
        productCode: Number(productId),
        site: 1078,
        sort: 'createdAt',
      },
    })
    setReviewData(initialData?.reviews ?? [], 1)
    setResumer({
      totalReviews: initialData?.total_reviews ?? 0,
      totalRatingsAverageNote: initialData?.total_ratings_average_note ?? 0,
      notes: initialData?.notes ?? [],
      productOffer: Number(initialData?.reviews?.[0]?.id),
    })
  }

  async function fetchingMore() {
    if (!pagination.variables) {
      return
    }

    if (pagination.currentPage >= pagination.totalPage) {
      return
    }

    setFetchLoading(true)

    setPagination((value) => ({
      ...value,
      currentPage: value.currentPage + 1,
    }))

    const { getRating } = await getRatingData({
      ...pagination.variables,
      page: pagination.currentPage + 1,
    })

    if (!getRating) {
      return
    }

    const {
      total_reviews: totalReviews,
      total_ratings_average_note: totalRatingsAverageNote,
      notes,
      current_page_number: currentPageNumber,
      total_page_number: totalPageNumber,
    } = getRating

    setFetchLoading(false)
    setPagination((value) => ({
      ...value,
      currentPage: currentPageNumber ?? 0,
      totalPage: totalPageNumber ?? 0,
      totalReviews: totalReviews ?? 0,
    }))

    if (!getRating?.reviews) {
      return
    }

    setResumer({
      totalReviews: totalReviews ?? 0,
      totalRatingsAverageNote: totalRatingsAverageNote ?? 0,
      notes: notes ?? [],
      productOffer: Number(getRating?.reviews?.[0]?.id),
    })
    setReviewData(getRating.reviews, currentPageNumber ?? 0)
  }

  function setReviewData(dataReview: ReviewT[], currentPage: number) {
    const listReview = dataReview.map((item) => ({
      originalLang: item,
      isTranslated: false,
    }))

    if (reviews.length > 0 && currentPage > 1) {
      const currentList = [...reviews, ...listReview]

      setReviews(currentList)

      return
    }

    setReviews(listReview)
  }

  async function translatedReview(reviewId: string, translated: boolean) {
    const list = [...reviews]
    const review = reviews.find((item) => item.originalLang.id === reviewId)

    if (!review) {
      return
    }

    if (translated) {
      review.isTranslated = false
      setReviews(list)

      return
    }

    if (review?.translatedLang?.id) {
      review.isTranslated = true
      setReviews(list)

      return
    }

    setIsLoadingTranslated({
      loading: true,
      reviewId,
    })
    const { getTranslateReview } = await getTranslation({
      reviewId: Number(reviewId),
    })

    setIsLoadingTranslated({
      loading: false,
      reviewId: review?.translatedLang?.id,
      error: !getTranslateReview,
    })

    if (review.translatedLang?.id) {
      review.isTranslated = true
      setReviews(list)

      return
    }

    review.translatedLang = getTranslateReview ?? ({} as ReviewT)
    review.isTranslated = true
    setReviews(list)
  }

  return (
    <ReviewContext.Provider
      value={{
        reviews,
        currentReviews,
        isLoadingTranslated,
        resumer,
        fetchLoading,
        setReviewData,
        translatedReview,
        handleGetReviewData,
        fetchingMore,
        isValidating: false,
        hasNextPage: pagination.currentPage < pagination.totalPage,
      }}
    >
      {children}
    </ReviewContext.Provider>
  )
}

export const useReview = () => useContext(ReviewContext)

export const fragment = graphql`
  fragment ReviewData_fragment on ReviewData {
    reviews {
      id
      language
      country
      body
      recommended
      count_up_vote
      count_vote
      firstname
      title
      note
      published_at
      country_label
      range_age
      range_used_since
    }
    reviews_per_page
    total_reviews
    reviews_count
    total_ratings_average_note
    notes {
      key
      count
      count_recommended
    }
    current_page_number
    total_page_number
    most_useful_positive {
      id
      language
      country
      body
      recommended
      count_up_vote
      count_vote
      firstname
      title
      note
      published_at
      country_label
      range_age
      range_used_since
    }
    most_useful_negative {
      id
      language
      country
      body
      recommended
      count_up_vote
      count_vote
      firstname
      title
      note
      published_at
      country_label
      range_age
      range_used_since
    }
  }
`
