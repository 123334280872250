/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react'
import ProductTitle from 'src/components/product/ProductTitle'
import { ProductContext } from 'src/components/product-page/context'

export const ProductDetailsTitle = ({ breakpoint }: { breakpoint: string }) => {
  const {
    currentSku,
    rating,
    productData: { customData },
  } = useContext(ProductContext)

  const { productBenefits } = customData

  return (
    <div
      className={breakpoint === 'desktop' ? 'hidden-mobile' : 'display-mobile'}
    >
      <ProductTitle
        brandName={customData.brand}
        title={customData.name}
        refNumber={currentSku.manufacturerCode}
        productBenefits={productBenefits}
        itemId={currentSku.itemId}
        seller={currentSku.sellers[0].sellerId}
        offer={currentSku.offer}
        flags={currentSku.flags}
        rating={
          rating
            ? {
                total_ratings_average_note:
                  rating.total_ratings_average_note ?? 0,
                total_reviews: rating.total_reviews ?? 0,
              }
            : null
        }
      />
    </div>
  )
}
