import Skeleton from 'react-loading-skeleton'
import Section from 'src/components/sections/Section'
import type { PropsWithChildren } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'

interface Props {
  loading?: boolean
}

function BoxSellerSkeleton({
  children,
  loading = true,
}: PropsWithChildren<Props>) {
  return loading ? (
    <Section className="mt-6 pt-3 px-4 pb-4 rounded-lg border border-gray border-solid">
      <div className="flex justify-between gap-4">
        <div className="w-44">
          <Skeleton height={16} />
        </div>
        <div className="w-4">
          <Skeleton height={16} />
        </div>
      </div>
      <div className="flex flex-row border-b border-gray border-solid pb-4">
        <div className="w-16">
          <Skeleton height={64} />
        </div>
        <div className="ml-2">
          <ul>
            <li className="w-28">
              <Skeleton height={16} />
            </li>
            <li className="w-20">
              <Skeleton height={20} />
            </li>
            <li className="w-52">
              <Skeleton height={16} />
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-row justify-between pt-4 items-end">
        <div>
          <ul>
            <li className="w-44">
              <Skeleton height={16} />
            </li>
            <li className="w-44">
              <Skeleton height={16} />
            </li>
          </ul>
        </div>
        <div>
          <div className="w-20">
            <Skeleton height={16} />
          </div>
        </div>
      </div>
    </Section>
  ) : (
    <>{children}</>
  )
}

export default BoxSellerSkeleton
