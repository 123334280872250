import axios from 'axios'
import { useContext, useState, useEffect } from 'react'
import { ProductContext } from 'src/components/product-page/context'
import BoxSellerSkeleton from 'src/components/product/BoxSeller/skeleton'

import { SellerData } from './sellerData'
import { CardData } from './cardData'
import { OrdersData } from './ordersData'
import type { SellerInfoQuery } from './utils/types'
import './styles.scss'

export function BoxSeller() {
  const { currentSku } = useContext(ProductContext)

  const [tooltip, setTooltip] = useState(false)
  const [tooltipMoreOffers, setTooltipMoreOffers] = useState(false)
  const [sellerData, setSellerData] = useState({} as SellerInfoQuery)

  // get seller by current sku sellected
  const sellerIdDecathlon = '1'
  const sellerIdDefault = currentSku?.sellers.find(
    (seller) => seller.sellerDefault
  )?.sellerId

  // requirement in grapqh to return seller data
  useEffect(() => {
    if (sellerIdDefault !== sellerIdDecathlon) {
      const getSellerData = async () => {
        const response = await axios.get(
          `/api/seller/getSeller?sellerId=${sellerIdDefault}`,
          {
            headers: {
              'Cache-Control': 'no-cache',
            },
          }
        )

        setSellerData(response.data.getSellerInfo)
      }

      getSellerData()
    }
  }, [sellerIdDefault])

  return (
    <>
      {Object.keys(sellerData).length !== 0 &&
      sellerIdDefault !== sellerIdDecathlon ? (
        <div>
          {/* fixed box seller data pdp */}
          <div className="content-box-seller rounded-lg font-roboto mt-6 pt-3 px-4 pb-4 border border-gray border-solid">
            <div className="top-content-seller flex justify-between">
              <span className="text-neutral06 text-xs">
                Este item é vendido e entregue por:
              </span>
              <button
                onClick={() => setTooltipMoreOffers(true)}
                className="icon-info-box-seller"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.3335 7.99992C1.3335 11.6819 4.31816 14.6666 8.00016 14.6666C11.6822 14.6666 14.6668 11.6819 14.6668 7.99992C14.6668 4.31792 11.6822 1.33325 8.00016 1.33325C4.31816 1.33325 1.3335 4.31792 1.3335 7.99992ZM13.3335 7.99992C13.3335 10.9454 10.9457 13.3333 8.00016 13.3333C5.05464 13.3333 2.66683 10.9454 2.66683 7.99992C2.66683 5.0544 5.05464 2.66659 8.00016 2.66659C10.9457 2.66659 13.3335 5.0544 13.3335 7.99992ZM8.66683 4.66658V5.99992H7.3335V4.66658H8.66683ZM8.66683 11.3333V7.33325H7.3335V11.3333H8.66683Z"
                    fill="#687787"
                  />
                </svg>
              </button>
            </div>

            <CardData sellerData={sellerData} border />

            <div className="bottom-content-seller flex justify-between items-end">
              <div className="seller-info flex flex-col">
                <OrdersData sellerData={sellerData} section="fixed" />
              </div>
              <button
                className="button-tooltip text-primaryDark text-sm font-bold"
                onClick={() => setTooltip(true)}
              >
                Ver mais
                <i className="inline-block align-middle ml-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.78145 7.99999L5.48145 4.69999L6.42411 3.75732L10.6668 7.99999L6.42411 12.2427L5.48145 11.3L8.78145 7.99999Z"
                      fill="#00689D"
                    />
                  </svg>
                </i>
              </button>
            </div>
          </div>
          {/* fixed box seller data pdp */}

          {/* modal more infos for seller */}
          {tooltip && (
            <section className="seller__modal fixed top-0 left-0 w-full h-full">
              <div className="overlayModal absolute top-0 left-0 w-full h-full bg-neutral10 opacity-25" />

              <div
                className="contentModal tooltip font-roboto md:p-6 p-4 bg-white w-full rounded-md flex flex-col justify-center items-center absolute top-1/2 right-1/2 z-10"
                aria-label="Modal Mais Informações"
                role="dialog"
              >
                <div className="content-scroll w-full overflow-x-hidden overflow-y-auto">
                  <div className="top-content-seller__modal items-center w-full justify-between mb-8 flex align-top">
                    <span className="text-neutral10 text-base">
                      Informações sobre o vendedor
                    </span>

                    <button
                      id="closeModal"
                      className="closeModal"
                      title="Fechar"
                      onClick={() => setTooltip(false)}
                    />
                  </div>

                  <CardData sellerData={sellerData} border={false} />

                  {sellerData.description !== undefined &&
                    sellerData.description !== '' && (
                      <div className="description-content text-neutral10 text-base mb-8">
                        {sellerData.description}
                      </div>
                    )}

                  <div className="seller-info-content mb-4">
                    <OrdersData sellerData={sellerData} section="modal" />
                  </div>

                  {sellerData.collectionLinkWeb !== '' && (
                    <button className="button-catalog-seller mb-6 bg-blue text-white text-base font-bold w-full text-center rounded py-3 border-0">
                      <a href={sellerData.collectionLinkWeb}>
                        Acessar catálogo do vendedor
                      </a>
                    </button>
                  )}

                  <div className="content-sale-seller w-full">
                    {sellerData.company !== '' ||
                    sellerData.taxCode !== '' ||
                    sellerData.address !== '' ? (
                      <div className="legal-information pt-6 border-t border-gray">
                        <span className="text-neutral10 text-base font-bold">
                          Informações legais
                        </span>

                        {sellerData.company !== '' && (
                          <SellerData
                            title="Razão Social"
                            description={sellerData.company}
                          />
                        )}

                        {sellerData.taxCode !== '' && (
                          <SellerData
                            title="CNPJ"
                            description={sellerData.taxCode}
                          />
                        )}

                        {sellerData.address !== '' && (
                          <SellerData
                            title="Endereço"
                            description={sellerData.address}
                          />
                        )}
                      </div>
                    ) : null}

                    {sellerData.exchangeReturnPolicy !== '' ||
                    sellerData.deliveryPolicy !== '' ||
                    sellerData.securityPrivacyPolicy !== '' ? (
                      <div className="general-purchasing-information">
                        <span className="text-neutral10 text-base font-bold mt-10 block">
                          Informações gerais da compra
                        </span>

                        {sellerData.exchangeReturnPolicy !== '' && (
                          <SellerData
                            title=" Política de trocas e devoluções"
                            description={sellerData.exchangeReturnPolicy}
                          />
                        )}

                        {sellerData.deliveryPolicy !== '' && (
                          <SellerData
                            title="Política de envio"
                            description={sellerData.deliveryPolicy}
                          />
                        )}

                        {sellerData.securityPrivacyPolicy !== '' && (
                          <SellerData
                            title="Política de Privacidade e Segurança"
                            description={sellerData.securityPrivacyPolicy}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* modal more infos for seller */}

          {/* modal more offer for icon info fixed box seller */}
          {tooltipMoreOffers && (
            <section className="boxSeller__modal">
              <div className="overlayModal absolute top-0 left-0 w-full h-full bg-neutral10 opacity-25" />

              <div
                className="contentModal boxSellerTooltip font-roboto text-center pt-4 px-4 pb-7 bg-white rounded-md flex flex-col justify-center items-center absolute right-1/2 top-1/2 z-10"
                aria-label="Modal Mais Informações"
                role="dialog"
              >
                <button
                  id="closeModal"
                  className="closeModal absolute top-0 right-0 p-4"
                  title="Fechar"
                  onClick={() => setTooltipMoreOffers(false)}
                />
                <h3 className="text-neutral10 text-2xl font-bold mt-6">
                  Mais ofertas para você
                </h3>
                <p className="text-deepGray text-sm font-normal mt-4 mx-auto mb-0">
                  A Decathlon em parceria com outras marcas e vendedores,
                  oferece o mesmo produto vendido e entregue por empresas
                  parceiras.
                  <br />
                  <br />
                  Mais variações de preços, tamanhos e cores para a sua
                  compra.Compare e compre sempre com a melhor condição. 💙
                </p>
              </div>
            </section>
          )}
          {/* modal more offer for icon info fixed box seller */}
        </div>
      ) : (
        <>
          {sellerIdDefault !== sellerIdDecathlon && (
            <BoxSellerSkeleton loading />
          )}
        </>
      )}
    </>
  )
}
