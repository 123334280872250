/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useMemo, useState } from 'react'
import type { ProductItem } from 'src/components/product-page/context/types/product.type'
import {
  ProductContext,
  useShipping,
} from 'src/components/product-page/context'
import { AvailabilityNotify } from 'src/components/product/AvailabilityNotify'
import { CallVendor } from 'src/components/product/CallVendor'
import BuyButton from 'src/components/ui/BuyButton'
import { useBuyButton } from 'src/sdk/cart/useBuyButton'
import Modal from 'src/components/ui/Modal'
import { formatCategoriesForEvent } from 'src/utils/formatted'
import { useCart } from 'src/sdk/cart/useCart'
import { Select } from 'src/components/common/Select'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

export function ProductActive() {
  const {
    currentSku,
    productData: { stockKeepingUnit, customData },
    setNotification,
    specificationNotSelected,
  } = useContext(ProductContext)

  const { ...cart } = useCart()
  const { cepIsValidForBuy, cep } = useShipping()

  const [openModal, setOpenModal] = useState(false)
  const [quantity, setQuantity] = useState(1)

  const buyDisabled = !!specificationNotSelected

  const { itemId, name, images, sellers } = currentSku

  const currentSeller = sellers.find((seller) => seller.sellerDefault)
  const availableQuantity = currentSeller!.commertialOffer.AvailableQuantity

  const { tenant } = useStoreConfig()
  const defaultMaxQuantity = tenant === 'B2C' ? 10 : 200
  const maxQuantityBuy =
    availableQuantity > defaultMaxQuantity
      ? defaultMaxQuantity
      : availableQuantity

  const category = formatCategoriesForEvent(customData.categories[0])
  const [clickButtonAddToCart, setClickButtonAddToCart] =
    useState<boolean>(false)

  const messageModal =
    'Não é possível comprar o Cartão Presente junto de outros produtos. Inicie uma nova compra para realizar a ação.'

  const variant = currentSku.variations.map((variation) => ({
    name: variation,
    values: currentSku[variation as keyof ProductItem] as string[],
  }))

  const specificationName = useCallback(() => {
    if (
      specificationNotSelected !== false &&
      specificationNotSelected !== null
    ) {
      return specificationNotSelected?.field.name.toLowerCase()
    }

    return ''
  }, [specificationNotSelected])

  const item = useMemo(
    () => ({
      setNotification,
      notificationText: `Você precisa escolher um(a) ${specificationName()}.`,
      buyDisabled,
      cepIsValidForBuy,
      id: itemId,
      category,
      price: currentSeller!.commertialOffer.Price,
      listPrice: currentSeller!.commertialOffer.ListPrice,
      seller: { identifier: currentSeller!.sellerId },
      sellerName: currentSeller!.sellerName,
      quantity,
      productRefId: stockKeepingUnit?.productRefId,
      refId: stockKeepingUnit?.refId,
      cep,
      itemOffered: {
        sku: itemId,
        name,
        gtin: stockKeepingUnit?.refId,
        image: images.map((image) => ({
          url: image.imageUrl,
          alternateName: image.imageText,
        })),
        brand: { name: customData.brand },
        isVariantOf: {
          productGroupID: customData.productId,
          name: customData.name,
        },
        variant,
        slug: customData.linkText,
        additionalProperty: [],
      },
    }),
    [
      buyDisabled,
      category,
      cep,
      cepIsValidForBuy,
      customData.brand,
      customData.linkText,
      customData.name,
      customData.productId,
      images,
      itemId,
      name,
      setNotification,
      specificationName,
      stockKeepingUnit?.productRefId,
      stockKeepingUnit?.refId,
      variant,
      quantity,
      currentSeller,
    ]
  )

  const buyProps = useBuyButton(
    item,
    clickButtonAddToCart,
    setClickButtonAddToCart
  )

  const handleBuyButton = () => {
    /*
      Regra para o botão de adicionar ao carrinho.
      Caso o carrinho já tenha algum produto diferente de cartão presente não é
      permitido adicionar cartão presente ao carrinho, necessário exibir um
      aviso para o usuário ao ser clicado, o inverso também é verdadeiro.
    */

    const currentProductIsGiftCard =
      item.itemOffered.isVariantOf.productGroupID === '11113'

    const currentProductIsNotGiftCard =
      item.itemOffered.isVariantOf.productGroupID !== '11113'

    const cartContainsGiftCard = cart.items.find(
      (cartItem) => cartItem.itemOffered.isVariantOf.productGroupID === '11113'
    )

    const cartContainsProductOtherThanGiftCard = cart.items.find(
      (cartItem) => cartItem.itemOffered.isVariantOf.productGroupID !== '11113'
    )

    if (cartContainsProductOtherThanGiftCard && currentProductIsGiftCard) {
      return setOpenModal(true)
    }

    if (cartContainsGiftCard && currentProductIsNotGiftCard) {
      return setOpenModal(true)
    }

    if (!cepIsValidForBuy && !cep) {
      setClickButtonAddToCart(true)
    }

    return buyProps.addToCart()
  }

  return currentSku.isAvailable ? (
    <>
      <div className="flex items-center gap-2 mt-6">
        <Select
          options={new Array(maxQuantityBuy).fill(null).map((_, index) => ({
            value: index + 1,
            label: String(index + 1),
          }))}
          className="min-w-[74px]"
          onChange={(value) => setQuantity(Number(value.value))}
        />
        <BuyButton onClick={handleBuyButton} {...buyProps}>
          Adicionar ao carrinho
        </BuyButton>
      </div>

      <div>
        <CallVendor className="mt-4" />
      </div>

      <Modal
        message={messageModal}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  ) : (
    <>
      <AvailabilityNotify selectedSku={currentSku} />

      <div>
        <CallVendor className="mt-4" />
      </div>
    </>
  )
}
