import { useEffect, useContext } from 'react'
import type { GetRatingQuery } from '@generated/graphql'
import { ProductContext } from 'src/components/product-page/context'

import { Resumer } from './Resumer'
import { useReview } from './hooks/useReviews'
import { ListReview } from './LIstReview'

type CustomerReviewsProps = {
  initialValue: GetRatingQuery['getRating']
}

export function CustomerReviews({ initialValue }: CustomerReviewsProps) {
  const { currentSku, product } = useContext(ProductContext)
  const { resumer, handleGetReviewData } = useReview()

  const sellerIdDecathlon = '1'
  const sellerIdDefault = currentSku?.sellers.find(
    (seller) => seller.sellerDefault
  )?.sellerId

  useEffect(() => {
    handleGetReviewData(initialValue, product.productId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      {sellerIdDefault === sellerIdDecathlon && (
        <section
          className="mt-20 max-w-screen-xl w-full mx-auto px-6"
          id="customer_reviews_section"
        >
          <h2 className="text-deepGray text-4xl text-center font-bold italic uppercase mb-6">
            AVALIAÇÕES do produto
          </h2>

          <div className="flex flex-col-reverse md:flex-row gap-x-6">
            {resumer?.totalReviews > 0 && (
              <div className="w-full md:w-[60%]">
                <ListReview />
              </div>
            )}
            <div
              className={`mb-10 md:mb-0 w-full ${
                resumer?.totalReviews ? 'md:w-[40%]' : 'w-full'
              }`}
            >
              <Resumer
                totalReviews={resumer.totalReviews}
                ratingNote={resumer.totalRatingsAverageNote}
                notes={resumer.notes}
              />
            </div>
          </div>
        </section>
      )}
    </>
  )
}
