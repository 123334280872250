/* eslint-disable prettier/prettier */
import { useState } from 'react'
import { Modal } from 'src/components/common/Modal'
import Icon from 'src/components/ui/Icon'
import { Image } from 'src/components/ui/Image'

type CallVendorProps = {
  className?: string
}

export function CallVendor({ className }: CallVendorProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className={`py-2 font-roboto flex items-center justify-center min-h-[48px] w-full bg-white text-primaryDark border border-gray text-xs font-bold ${className}`}
      >
        <Icon name="WhatsAppIcon" width={24} height={24} />
        <p className="ml-2 font-roboto">Fale com um especialista</p>
      </button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="p-8">
          <button
            onClick={() => setIsOpen(false)}
            className="text-blue text-2xl ml-auto mr-0 flex"
          >
            X
          </button>

          <div className=" py-4 xl:px-20 lg:px-20 md:px-20 px-7 flex flex-col items-center justify-center">
            <p className="mb-2">
              Escaneie o QR-CODE na <strong>câmera do seu celular</strong>
            </p>
            <Image
              className="m-1"
              src="https://decathlonstore.vtexassets.com/arquivos/qr-code-pdp.png"
              alt="Qr Code whatsapp"
              loading="lazy"
              width={228}
              height={228}
            />
            <a
              href="https://api.whatsapp.com/send/?phone=5511947312606&text=Olá, gostaria de falar com um especialista do esporte."
              className="mt-4 border border-green uppercase text-green py-4 w-60 font-bold text-center"
            >
              Abrir whatsapp
            </a>
            <p className="my-6">ou se preferir:</p>
            <p className="xl:text-3xl lg:text-3xl md:text-3xl text-2xl font-bold text-green">
              55 11 94731-2606
            </p>
          </div>
        </div>
      </Modal>
    </>
  )
}
