interface Props {
  urlImage: string
  altImg: string
}

export function ImageData({ urlImage, altImg }: Props) {
  const regex = /\b(jpe?g|png|svg)\b/gi
  const inputString = urlImage
  const matches = inputString.match(regex)

  if (matches?.length) {
    return (
      <div className="section-image-seller mr-4">
        <div className="container">
          <img src={inputString} alt={altImg} className="rounded-md h-auto" />
        </div>
      </div>
    )
  }

  return null
}
