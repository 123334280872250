import { useMemo } from 'react'
import { Spinner } from 'src/components/common/Spinner'

import { useReview } from './hooks/useReviews'
import { ReviewCard } from './ReviewCard'

export function ListReview() {
  const { currentReviews, hasNextPage, fetchingMore, fetchLoading } =
    useReview()

  const reviewData = useMemo(
    () =>
      currentReviews.map((review) => {
        return {
          user: {
            name: review.firstname,
            country: review.country_label,
            years: review.range_age,
          },
          id: review.id,
          stars: review.note,
          coutVotes: review.count_vote,
          description: review.body,
          title: review.title,
          language: review.language,
          isTranslated: review.isTranslated,
        }
      }),
    [currentReviews]
  )

  return (
    <>
      <ul className=" gap-y-8 flex flex-col">
        {reviewData?.map(
          (review) =>
            review && (
              <li key={review.id}>
                <ReviewCard
                  reviewId={review.id}
                  description={review.description}
                  stars={review.stars}
                  user={review.user}
                  votes={review.coutVotes}
                  language={review.language}
                  isTranslated={review.isTranslated}
                />
              </li>
            )
        )}
      </ul>

      {hasNextPage && reviewData.length > 0 && (
        <button
          onClick={() => fetchingMore()}
          className="text-bluePrimary px-10 py-2 border border-backgroundPrimary mx-auto mt-8 flex justify-center items-center w-full md:w-auto"
        >
          {fetchLoading ? <Spinner /> : 'Carregar mais'}
        </button>
      )}
    </>
  )
}
