import { ImageData } from '../imageData'
import type { SellerInfoQuery } from '../utils/types'

interface Props {
  sellerData: SellerInfoQuery
  border: boolean
}

export function CardData({ sellerData, border }: Props) {
  return (
    <div
      className={
        border
          ? 'body-content-seller mt-4 mb-4 pb-4 flex items-center border-b border-gray'
          : 'body-content-seller mt-4 mb-4 pb-4 flex items-center'
      }
    >
      <ImageData urlImage={sellerData.logo} altImg={sellerData.name} />

      <div className="section-info-seller">
        <div className="seller-verified text-cashbackGreen text-xs font-bold">
          <i className="inline-block align-middle mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.478 2.38408L8 1.16675L2.522 2.38408C2.21733 2.45208 2 2.72208 2 3.03475V9.69341C2 11.0301 2.668 12.2787 3.78133 13.0207L8 15.8334L12.2187 13.0207C13.3313 12.2794 14 11.0301 14 9.69275V3.03475C14 2.72208 13.7827 2.45208 13.478 2.38408ZM7.668 9.28075L10.968 5.98141L11.9113 6.92408L7.66867 11.1667L4.84 8.33808L5.78267 7.39541L7.668 9.28075Z"
                fill="#02986E"
              />
            </svg>
          </i>
          Vendedor verificado
        </div>

        {sellerData.name !== '' && (
          <div className="text-neutral10 font-bold text-base my-1">
            {sellerData.name}
          </div>
        )}

        {sellerData.startTime !== '' && (
          <div className="seller-data-time text-deepGray text-xs">
            {sellerData.startTime}
          </div>
        )}
      </div>
    </div>
  )
}
