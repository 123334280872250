import { useEffect, useContext, useState } from 'react'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import { GridImage } from 'src/components/product/GridImage'
import { SkuSelector } from 'src/components/product-page/Selector'
import { Description } from 'src/components/product/Description'
import { useProductEventPix } from 'src/utils/gtmEvents'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import type { AnalyticsItem } from 'src/sdk/analytics/types'
import type { CurrencyCode, ViewItemEvent } from '@faststore/sdk'
import { formatCategoriesForEvent } from 'src/utils/formatted'
import type { ItemListElement } from 'src/components/product-page/types'
import axios from 'axios'
import { ChampionPdp } from 'src/components/product/ChampionPdp'
import { isEmpty } from 'ramda'
import { ProductContext } from 'src/components/product-page/context'
import { SelectorNotification } from 'src/components/product-page/SelectorNotification'
import { BoxSeller } from 'src/components/product/BoxSeller'
import ShelfLinx from 'src/components/ShelfLinx/ShelfLinx'
import { SelectShipping } from 'src/components/product/SelectShipping'

import { api } from '../../../../store.config'
import type { ChampionProps } from './types/index'
import { ProductDetailsTitle } from './ProductDetailsTitle'
import { ProductActive } from './ProductActive'
import { ProductPricing } from './ProductPricing'
import './styles.scss'
import CommercialsBenefits from './CommercialsBenefits'

function ProductDetails() {
  const [champion, setChampion] = useState<ChampionProps[]>([])

  const {
    currentSku,
    notification,
    productData: { customData },
  } = useContext(ProductContext)

  useEffect(() => {
    axios
      .get(
        `https://${api.storeId}.myvtex.com/api/dataentities/product/search?_schema=pdpcampea&_where=(productId=${customData.productId})`
      )
      .then((response) => {
        const { data } = response

        setChampion(data)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }, [customData])

  const { currency } = useSession()
  const categoryName = formatCategoriesForEvent(customData.categories[0])

  const category = customData.sport
  const sellerProductItem =
    currentSku.sellers.find((seller) => seller.sellerDefault) ??
    currentSku.sellers[0]

  useEffect(() => {
    sendAnalyticsEvent<ViewItemEvent<AnalyticsItem>>({
      name: 'view_item',
      params: {
        currency: currency.code as CurrencyCode,
        value: currentSku.sellers[0].commertialOffer.Price ?? 0,
        items: [
          {
            item_id: customData.productId,
            item_name: customData.name,
            item_brand: customData.brand,
            item_variant: currentSku.itemId,
            item_category2: category,
            price: currentSku.offer.price ?? 0,
            discount: currentSku.offer.listPrice - currentSku.offer.price,
            currency: currency.code as CurrencyCode,
            item_variant_name: currentSku.name,
            product_reference_id: currentSku.referenceId,
            arborescence: categoryName,
          },
        ],
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useProductEventPix(
      sellerProductItem.commertialOffer.Price,
      sellerProductItem.commertialOffer.ListPrice,
      sellerProductItem.sellerId
    )
  }, [sellerProductItem])

  const breadcrumbList: ItemListElement[] = [...customData.categories]
    .sort((a, b) => a.length - b.length)
    .map((path: string, index: number) => {
      return {
        item: path,
        name: path.slice(1).slice(0, -1).split('/').pop()!,
        position: index + 1,
      }
    })

  return (
    <div className="max-w-screen-xl w-full mx-auto ppp:px-0 pp:px-6">
      <Breadcrumb breadcrumbList={breadcrumbList} />

      <section className="mt-8">
        <section className="flex flex-col md:flex-row gap-x-6">
          <ProductDetailsTitle breakpoint="mobile" />

          <section className="w-full md:w-[60%] lg:w-[65%]">
            <GridImage currentSku={currentSku} />
          </section>

          <section className="flex-1 w-full md:w-[35%]">
            <ProductDetailsTitle breakpoint="desktop" />
            <ProductPricing
              item={currentSku}
              productId={customData.productId}
            />
            <SkuSelector />

            {notification?.type === 'variations' && (
              <SelectorNotification text={notification.message ?? ''} />
            )}

            <ProductActive />

            <SelectShipping
              products={[
                {
                  productId: customData.productId,
                  itemId: currentSku.itemId,
                  quantity: '1',
                  seller: sellerProductItem.sellerId,
                },
              ]}
              sellerName={sellerProductItem.sellerName ?? 'Decathlon'}
            />

            <CommercialsBenefits />

            <BoxSeller />
          </section>
        </section>

        <section className="flex gap-x-6 mt-16">
          <section className="w-full md:w-[60%]">
            <ShelfLinx page="product" position="top" />
            <Description
              description={customData.description}
              productBenefits={customData.productBenefits}
              productDocuments={customData.productDocuments}
              technicalInformation={customData.technicalInformation}
            />
          </section>
        </section>

        <div
          className={`${
            isEmpty(champion[0]?.components?.promise) ? 'hidden' : 'flex'
          }`}
        >
          <ChampionPdp components={champion[0]?.components} />
        </div>
      </section>
    </div>
  )
}

export default ProductDetails
