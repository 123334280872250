interface StoreInfo {
  title: string
  description: string
}

export function SellerData({ title, description }: StoreInfo) {
  return (
    <div className="flex flex-col mt-3 ">
      <span className="title-item mb-[2px] text-sm text-neutral06">
        {title}
      </span>
      <span className="description-item text-sm text-neutral10">
        {description}
      </span>
    </div>
  )
}
