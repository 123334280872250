export function ArrowBlue() {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.287 6.70848C10.897 7.09848 10.267 7.09848 9.87704 6.70848L5.99703 2.82848L2.11701 6.70848C1.72701 7.09848 1.097 7.09848 0.707003 6.70848C0.317002 6.31848 0.317002 5.68848 0.707003 5.29848L5.29702 0.708477C5.68702 0.318477 6.31703 0.318477 6.70703 0.708477L11.297 5.29848C11.677 5.67848 11.677 6.31848 11.287 6.70848Z"
        fill="#007cb9"
      />
    </svg>
  )
}
