import type { SellerInfoQuery } from '../utils/types'

interface Props {
  sellerData: SellerInfoQuery
  section: string
}

export function OrdersData({ sellerData, section }: Props) {
  if (sellerData.quantityProducts !== '') {
    return (
      <div
        className={
          section === 'fixed'
            ? 'flex flex-col'
            : 'flex flex-row items-center justify-between'
        }
      >
        {sellerData.resumeSales !== '' &&
          sellerData.resumeSalesDescription !== '' && (
            <span
              className={
                section === 'fixed'
                  ? 'orders-info text-deepGray text-xs mb-1'
                  : 'orders-info text-deepGray text-sm flex items-center text-center justify-center rounded border-gray border py-2.5 mb-2 md:mb-0'
              }
            >
              {section === 'fixed' ? (
                <>
                  {sellerData.resumeSales}&nbsp;
                  {sellerData.resumeSalesDescription}
                </>
              ) : (
                <>
                  <strong>{sellerData.resumeSales}</strong>&nbsp;
                  {sellerData.resumeSalesDescription}
                </>
              )}
            </span>
          )}

        <span
          className={
            section === 'fixed'
              ? 'products-info text-deepGray text-xs'
              : 'products-info text-deepGray text-sm flex items-center text-center justify-center rounded border-gray border py-2.5'
          }
        >
          {section === 'fixed' ? (
            <>{sellerData.quantityProducts} produtos em catálogo</>
          ) : (
            <>
              <strong>{sellerData.quantityProducts} produtos&nbsp; </strong> em
              catálogo
            </>
          )}
        </span>
      </div>
    )
  }

  return (
    <div className=" text-deepGray text-xs">
      <span>Saiba mais sobre o vendedor</span>
    </div>
  )
}
