import { useContext, useState } from 'react'
import { Modal } from 'src/components/common/Modal'
import { ProductContext } from 'src/components/product-page/context'

import { ArrowBlue } from './Icons/ArrowBlue'
import { StarsIcon } from './Icons/StarsIcon'

type ResumerProps = {
  totalReviews: number
  ratingNote: number
  notes: Array<
    { count: number; count_recommended: number; key: number } | null | undefined
  >
}

const notesDefault: ResumerProps['notes'] = [
  { count: 0, count_recommended: 0, key: 5 },
  { count: 0, count_recommended: 0, key: 4 },
  { count: 0, count_recommended: 0, key: 3 },
  { count: 0, count_recommended: 0, key: 2 },
  { count: 0, count_recommended: 0, key: 1 },
]

function sortNotesResumer(notes: ResumerProps['notes']) {
  const list = Array.from([...notes])

  list.sort((a, b) => b!.key - a!.key)

  return list
}

export function Resumer({
  notes = notesDefault,
  ratingNote = 0,
  totalReviews = 0,
}: ResumerProps) {
  const {
    currentSku: { manufacturerCode },
  } = useContext(ProductContext)

  const [isOpenNotes, setIsOpenNotes] = useState(true)
  const [isOpenForm, setIsOpenForm] = useState(false)

  const sortNotes = sortNotesResumer(notes)

  return totalReviews < 1 ? (
    <div className="mt-16 mb-20">
      <p className="text-center mt-10">
        Seja o primeiro a avaliar este produto
      </p>
      <button
        onClick={() => setIsOpenForm(true)}
        className="bg-blue text-white uppercase px-4 py-2 mx-auto mt-6 flex"
      >
        Avaliar produto
      </button>

      <Modal
        isOpen={isOpenForm}
        onClose={() => setIsOpenForm(false)}
        positionContent="center"
        className="w-full max-w-[95%] md:max-w-xl h-[90vh]"
      >
        <div className="w-full h-full py-6">
          <iframe
            title="Form New Review Product"
            src={`https://reviews.decathlon.com/pt_BR/review/new/dktbr/product/${manufacturerCode}`}
            className="h-full w-full"
          >
            SEU NAVEGADOR NÃO SUPORTE IFRAMES
          </iframe>
        </div>
      </Modal>
    </div>
  ) : (
    <section className="bg-neutral02 rounded px-8 py-16">
      <h3 className="text-3xl italic font-bold text-deepGray uppercase text-center">
        Nota média
      </h3>

      <div className="flex items-center justify-center mt-6">
        <StarsIcon height={38} width={38} />
        <span className="text-6xl text-primaryDark font-bold ml-2">
          {ratingNote?.toFixed(1)}
        </span>
      </div>

      <p className="text-center text-lg font-bold mt-6 text-deepGray">
        {totalReviews} avaliações
      </p>

      <button
        onClick={() => setIsOpenForm(true)}
        className="bg-blue text-white uppercase px-4 py-2 mx-auto mt-14 flex"
      >
        Avaliar produto
      </button>

      <Modal
        isOpen={isOpenForm}
        onClose={() => setIsOpenForm(false)}
        positionContent="center"
        className="w-full max-w-[95%] md:max-w-xl h-[90vh]"
      >
        <div className="w-full h-full py-6">
          <iframe
            title="Form New Review Product"
            src={`https://reviews.decathlon.com/pt_BR/review/new/dktbr/product/${manufacturerCode}`}
            className="h-full w-full"
          >
            SEU NAVEGADOR NÃO SUPORTE IFRAMES
          </iframe>
        </div>
      </Modal>
      <button
        onClick={() => setIsOpenNotes(!isOpenNotes)}
        className="display-mobile flex gap-2 font-bold text-blue01 justify-center items-center mx-auto mt-10"
      >
        <p>Ver menos</p>
        <div
          className={`transition duration-300 ease-in-out ${
            !isOpenNotes && 'rotate-180'
          }`}
        >
          <ArrowBlue />
        </div>
      </button>

      {isOpenNotes && (
        <div className="mt-10 md:mt-20">
          <ul className="flex flex-col gap-y-6">
            {sortNotes?.map((note) => (
              <li key={note?.key} className="flex items-center">
                <p className="flex items-center mr-2 text-primaryDark text-2xl font-bold">
                  <StarsIcon height={18} width={18} />
                  {note?.key}
                </p>

                <div className="flex-1 relative bg-offWhite h-3.5 rounded-full">
                  <div
                    style={{
                      width: `
                        ${Number(
                          note?.count === 0
                            ? 0
                            : (note!.count / totalReviews) * 100
                        )}%`,
                    }}
                    className="bg-darkBlue absolute top-0 left-0 h-full rounded-full"
                  />
                </div>

                <p className="ml-2">{note?.count}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  )
}
