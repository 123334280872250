import { gql } from '@vtex/graphql-utils'
import { useLazyQuery } from 'src/sdk/graphql/useLazyQuery'
import type {
  GetTranslateReviewQuery as Query,
  GetTranslateReviewQueryVariables as Variables,
} from '@generated/graphql'

const query = gql`
  query GetTranslateReview($reviewId: Int!) {
    getTranslateReview(reviewId: $reviewId) {
      id
      language
      country
      body
      recommended
      count_up_vote
      count_vote
      firstname
      title
      note
      published_at
      country_label
      range_age
      range_used_since
    }
  }
`

export function useTranslationReview() {
  return useLazyQuery<Query, Variables>(query, {} as Variables)
}
