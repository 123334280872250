import type { ComponentType } from 'react'

import { PictureSelector } from './PictureSelector'
import { DefaultSelector } from './DefaultSelector'
import type { Variation } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const COMPONENTS: Record<string, ComponentType<any>> = {
  Cor: PictureSelector,
  Sabor: DefaultSelector,
  Tamanho: DefaultSelector,
  Valor: DefaultSelector,
  Voltagem: DefaultSelector,
}

interface Props {
  variations: Variation[]
  onClickVariation: (name: any, value: string) => void
}

export function RenderedSelectors({ variations, onClickVariation }: Props) {
  return (
    <>
      {variations.map((variation) => {
        const variationName = variation.field.name
        const Component = COMPONENTS[variationName]

        const props = {
          variation,
          onClickVariation,
        }

        if (!Component) {
          return <DefaultSelector key={variation.field.id} {...props} />
        }

        return <Component key={variation.field.id} {...props} />
      })}
    </>
  )
}
