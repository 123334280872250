import type {
  ReviewVoteMutationVariables,
  ReviewVoteMutation,
} from '@generated/graphql'
import { gql } from '@vtex/graphql-utils'
import { request } from 'src/sdk/graphql/request'

const query = gql`
  mutation ReviewVote($reviewId: String!, $vote: Int!) {
    postReviewVote(reviewId: $reviewId, vote: $vote) {
      openvoice
    }
  }
`

export function onVoteReview({ reviewId, vote }: ReviewVoteMutationVariables) {
  return request<ReviewVoteMutation, ReviewVoteMutationVariables>(query, {
    reviewId,
    vote,
  })
}
