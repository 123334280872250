import { Button as UIButton } from '@faststore/ui'
import type { ButtonProps } from '@faststore/ui'

interface Props extends ButtonProps {
  onClick: any
}

function BuyButton({ children, ...props }: Props) {
  return (
    <UIButton
      className="bg-[#007DBC] w-full rounded text-white uppercase cursor-pointer"
      data-store-buy-button
      {...props}
    >
      {children}
    </UIButton>
  )
}

export default BuyButton
