import { useEffect, useRef } from 'react'
import { InformationIcon } from 'src/components/Icons/InformationIcon'

export function SelectorNotification({ text }: { text: string }) {
  const element = useRef<HTMLParagraphElement | null>(null)

  useEffect(() => {
    element?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [])

  return (
    <p
      ref={element}
      className="dialog text-xs text-red-500 text-deepGray flex items-start md:items-center mt-5 gap-x-1 md:leading-[0.65rem]"
    >
      <InformationIcon color="#EE000C" /> {text}
    </p>
  )
}
