import { useContext, useState } from 'react'
import { navigate } from 'gatsby'
import { ProductContext } from 'src/components/product-page/context'
import type { CommercialBenefit } from 'src/components/product-page/context/types/product.type'

import { ModalCommercialsBenefits } from '../ModalCommercialsBenefits'

function CommercialsBenefits() {
  const { product } = useContext(ProductContext)

  const { commercialsBenefits } = product

  const [showModalCommercialsBenefits, setShowModalCommercialsBenefits] =
    useState(false)

  const [modalComercialsBenefitsData, setModalCommercialsBenefitsData] =
    useState<CommercialBenefit>({} as CommercialBenefit)

  const handleModalCommercialsBenefits = (
    comercialBenefitsData: CommercialBenefit
  ) => {
    const { link } = comercialBenefitsData
    const { action, url } = link

    if (action === 'open-modal') {
      setShowModalCommercialsBenefits(true)

      setModalCommercialsBenefitsData(comercialBenefitsData)

      return
    }

    return navigate(url ?? '')
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-5">
        {commercialsBenefits?.length
          ? commercialsBenefits.map((commercialsBenefit) => {
              const { icon, link, name, text } = commercialsBenefit

              return (
                <div
                  key={name}
                  className={`flex flex-1 flex-col justify-between
               border-[1px] rounded-lg border-gray gap-2 p-3 mt-4`}
                >
                  <img src={icon} alt={`icon-${name}`} width={24} height={24} />
                  <p className="md:w-[170px] sm:w-[132px] flex flex-col font-roboto font-normal text-sm text-neutral10 gap-2">
                    {text}
                  </p>
                  <span
                    aria-hidden="true"
                    onClick={() => {
                      handleModalCommercialsBenefits(
                        commercialsBenefit as CommercialBenefit
                      )
                    }}
                    className="font-roboto font-normal text-xs text-darkGray underline cursor-pointer"
                  >
                    {link.text}
                  </span>
                </div>
              )
            })
          : null}
      </div>

      {showModalCommercialsBenefits && (
        <ModalCommercialsBenefits
          openModalCommercialsBenefits={showModalCommercialsBenefits}
          handleCloseCommercialsBenefitsModal={setShowModalCommercialsBenefits}
          comercialBenefitsData={modalComercialsBenefitsData}
        />
      )}
    </>
  )
}

export default CommercialsBenefits
