import type { MouseEvent } from 'react'
import { useEffect, useState } from 'react'

type SelectProp = {
  options: Array<{
    label: string
    value: string | number
  }>
  className?: string
  onChange?: (value: { label: string; value: string | number }) => void
}

export function Select({ options, className, onChange }: SelectProp) {
  const [optSelect, setOptSelect] = useState<string | number>(options[0].value)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handler = () => setIsOpen(false)

    window.addEventListener('click', handler)

    return () => {
      window.removeEventListener('click', handler)
    }
  }, [])

  const handleOpenOptions = (e: MouseEvent) => {
    e.stopPropagation()
    setIsOpen((current) => !current)
  }

  return (
    <div className={`relative h-[48px] ${className}`}>
      <button
        className="flex items-center justify-center font-bold font-roboto gap-[17px] border border-[#8996A2] h-full w-full rounded"
        onClick={handleOpenOptions}
      >
        {options.find((item) => item.value === optSelect)?.value}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
        >
          <path
            d="M4.99999 3.78138L8.29999 0.481384L9.24266 1.42405L4.99999 5.66672L0.757324 1.42405L1.69999 0.481384L4.99999 3.78138Z"
            fill="#687787"
          />
        </svg>
      </button>
      {isOpen && (
        <ul className="absolute bg-white px-2 top-[50px] right-0 h-fit z-50 shadow-[0px 24px 24px rgba(0, 83, 125, 0.1)] border border-gray rounded w-full max-h-[200px] overflow-auto">
          {options.map(({ label, value }, index) => (
            <li key={value}>
              <label
                onClick={() => {
                  setOptSelect(value)
                  onChange?.(
                    options.find((item) => item.value === value) ?? options[0]
                  )
                }}
                aria-hidden="true"
                className={`relative flex items-center justify-between text-sm font-roboto py-[12px] px-2 ${
                  index > 0 ? 'border-t border-gray' : ''
                } ${
                  value === optSelect ? 'text-[#007DBC]' : 'text-neutral10'
                } `}
              >
                <input
                  type="radio"
                  name="sort"
                  value={value}
                  className="w-0 h-0 invisible absolute top-0 left-0"
                />
                <p>{label}</p>
                <span className="w-[20px]">
                  {value === optSelect && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="13"
                      viewBox="0 0 18 13"
                      fill="none"
                    >
                      <path
                        d="M6.99999 10.172L16.192 0.979004L17.607 2.393L6.99999 13L0.635986 6.636L2.04999 5.222L6.99999 10.172Z"
                        fill="#007DBC"
                      />
                    </svg>
                  )}
                </span>
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
