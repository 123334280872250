export function AlertIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 0.25C4.98 0.25 0.5 4.73 0.5 10.25C0.5 15.77 4.98 20.25 10.5 20.25C16.02 20.25 20.5 15.77 20.5 10.25C20.5 4.73 16.02 0.25 10.5 0.25ZM9.5 15.25V13.25H11.5V15.25H9.5ZM9.5 5.25V11.25H11.5V5.25H9.5Z"
        fill="#344450"
      />
    </svg>
  )
}
